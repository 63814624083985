// @flow
import ContactModel from 'Stores/ContactModel';
import DealerSettingsModel from 'Stores/DealerSettingsModel';
import { addPrefix } from 'Utils';

declare var API_BASE_URL: string;

export default class DealerModel {
  // mazda customer number of the current dealer/location
  id: number;
  // mazda customer number of the main dealer/location
  mainDealerId: number;
  demodernDealerId: number;
  name: string;
  // hourly service costs for this dealer
  hourlyRate: number;
  hasLogo: boolean;
  hasFMMData: boolean = false;
  mainContact: ContactModel = new ContactModel();
  legalContact: ContactModel = new ContactModel();
  routes: Array<Object> = [];
  menu: Array<Object> = [];
  branches: Array<Object> = [];
  openingHours: Array<Object> = [];
  settings: DealerSettingsModel = new DealerSettingsModel();
  registrationCourt: string = '';
  registrationNumber: string = '';
  taxId: string = '';
  authorisedRepresentatives: Array<string> = [];
  pageIdsWithHeaderModeMini: Array<number> = [];

  constructor() {
    this.hydrate({ ...window.dealer } || {});
  }

  hydrate = (data: Object) => {
    if (!data.fmmData) delete data.fmmData;
    data = {
      dealerId: '0',
      name: '',
      hasLogo: false,
      sitemap: [],
      fmmData: {
        data: {
          dealer: {
            contactDetails: {
              legalAddress: {
                geoCoordinates: {
                  latitude: 0,
                  longitude: 0,
                },
              },
            },
            location: {
              lat: 0,
              lon: 0,
            },
          },
        },
      },
      dealerLocations: [],
      ...data,
    };

    const currentLocation = data.dealerLocations.filter(
      location => location.mazdaId === data.dealerId
    );
    this.id = data.dealerId;
    this.name = data.dealerId === '88888' ? 'Dealer88' : data.fmmData.data.dealer.name;
    this.name = window.dealer.name = currentLocation.length ? currentLocation[0].name : this.name;
    this.hasLogo = data.hasLogo;
    this.routes = this.hydrateRoutes(data.sitemap);
    this.pageIdsWithHeaderModeMini = this.hydratePageIdsWithHeaderModeMini(this.routes);
    this.menu = this.hydrateMenu(data.sitemap);
    this.branches = this.filterOpeningHoursInLocations(data.dealerLocations) || [];
    this.settings = new DealerSettingsModel(data.settings || {});

    data = data.fmmData.data.dealer;

    this.hasFMMData = !!data.id;
    this.hourlyRate = data.hourlyRate;
    this.mainDealerId = data.mainDealer && data.mainDealer.mazdaId;
    this.demodernDealerId = data.id;
    this.mainContact = new ContactModel(this.hydrateMainContact(data));
    this.legalContact = new ContactModel(this.hydrateLegalContact(data));
    this.openingHours = this.hydrateOpeningHours(data);
    this.registrationCourt = data.registrationCourt;
    this.registrationNumber = data.registrationNumber;
    this.taxId = data.taxId;
    this.authorisedRepresentatives = data.authorisedRepresentatives || [];
    this.partAccessoryDiscount = data.partAccessoryDiscount;

    /* updating the window.dealer in case of a deactivated */
    if (
      window.dealer &&
      (window.dealer.dealerId === '0' || window.dealer.dealerId === 'undefined')
    ) {
      console.log('update window.dealer due to bad dealer id');
      window.dealer = this;
    }
  };

  onboardingComplete = () => {
    return this.settings.onboardingComplete;
  };

  /**
   * The main address of the dealer (location!)
   */
  hydrateMainContact(data: Object = {}) {
    return {
      ...data.contactDetails.mainAddress,
      phone: data.contactDetails.phone,
      email: data.contactDetails.email,
      lat: data.location.lat,
      lng: data.location.lon,
    };
  }

  /**
   * The legal address of the dealer company (may differ from the main address)
   */
  hydrateLegalContact(data: Object = {}) {
    return {
      ...data.contactDetails.legalAddress,
      phone: data.contactDetails.phone,
      email: data.contactDetails.email,
      lat: data.contactDetails.legalAddress.geoCoordinates.latitude,
      lng: data.contactDetails.legalAddress.geoCoordinates.longitude,
    };
  }

  getName = () => {
    return addPrefix(this.name, 'Autohaus ');
  };

  isServicePartnerOnly = () => {
    return this.settings.servicePartnerOnly;
  };

  isSalesPartnerOnly = () => {
    return this.settings.salesPartnerOnly;
  };

  hydrateMenu(sitemap: Array<Object>) {
    // JSON.parse & JSON.stringify for copy object to new variable NOT by reference
    // to prevent filter sitemap for generate routes
    const filteredSitemap = JSON.parse(JSON.stringify(sitemap.filter(entry => !entry.hideInMenu)));

    return filteredSitemap.map(lvl1 => {
      if (lvl1.subentries) {
        lvl1.subentries = lvl1.subentries.filter(entry => !entry.hideInMenu);

        lvl1.subentries.map(lvl2 => {
          if (lvl2.subentries) {
            lvl2.subentries = lvl2.subentries.filter(entry => !entry.hideInMenu);
          }

          if (lvl2.subentries && window.dealer.dealerLocations.length < 2) {
            lvl2.subentries = lvl2.subentries.map(entry => {
              if (entry.label === 'Unsere Standorte') {
                return { ...entry, label: 'Standortdetails' };
              } else {
                return entry;
              }
            });
          }

          return lvl2;
        });
      }

      return lvl1;
    });
  }

  hydrateOpeningHours(data: Object = {}) {
    const openingHours = [];

    const openingHoursForServices = this.filterOpeningHoursValid(data.openingHoursForServices);
    if (data.openingHoursForServices && openingHoursForServices.length) {
      openingHours.push({
        headline: 'ÖFFNUNGSZEITEN WERKSTATT',
        entries: openingHoursForServices,
      });
    }

    const openingHoursForSales = this.filterOpeningHoursValid(data.openingHoursForSales);
    if (data.openingHoursForSales && openingHoursForSales.length) {
      openingHours.push({
        headline: 'ÖFFNUNGSZEITEN VERKAUF',
        entries: openingHoursForSales,
      });
    }

    const openingHoursForShowroom = this.filterOpeningHoursValid(data.openingHoursForShowroom);
    if (data.openingHoursForShowroom && openingHoursForShowroom.length) {
      openingHours.push({
        headline: 'ÖFFNUNGSZEITEN SHOWROOM',
        entries: openingHoursForShowroom,
      });
    }

    return openingHours;
  }

  filterOpeningHoursInLocations(locations) {
    return locations.map(location => {
      return {
        ...location,
        openingHoursForSales: this.filterOpeningHoursValid(location.openingHoursForSales),
        openingHoursForServices: this.filterOpeningHoursValid(location.openingHoursForServices),
      };
    });
  }

  filterOpeningHoursValid(entries: Array<Object> = []) {
    if (!entries) return entries;
    return entries.filter(entry => entry.from !== 'N/A' && entry.to !== 'N/A' && entry.dayOfWeekDe);
  }

  hydrateRoutes(sitemap: Array<Object>) {
    let routes = [];
    // TODO Refactor and unit test!
    sitemap.forEach(route => {
      routes.push(route);
      route.subentries &&
        route.subentries.forEach(route => {
          // we do not need routes on this level
          // routes.push(route);
          route.subentries &&
            route.subentries.forEach(route => {
              routes.push(route);
              route.subentries &&
                route.subentries.forEach(route => {
                  routes.push(route);
                });
            });
        });
    });

    routes = routes.filter(route => route.path.length);

    return routes;
  }

  hydratePageIdsWithHeaderModeMini(sitemap: Array<Object>) {
    return sitemap.filter(item => item.headerMode === 'mini').map(item => item.id);
  }
}
