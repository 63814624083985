// @flow
import BaseModel from 'Stores/BaseModel';
import { hasCookie, getCookie, setCookie } from 'Utils/index';

type CookieTypes = {
  performance: boolean,
  komfort: boolean,
  marketing: boolean,
};

const COOKIE_NAME = 'privacySettings';

class PrivacyModel extends BaseModel {
  cookieTypes: CookieTypes = {
    performance: false,
    komfort: false,
    marketing: false,
  };

  showBanner: boolean = true;

  constructor() {
    super();

    if (hasCookie(COOKIE_NAME)) {
      this.hydrate(JSON.parse(getCookie(COOKIE_NAME)));
      this.checkGaTracking();
    }
  }

  updateCookie = (name: string, enabled: boolean) => {
    this.cookieTypes[name] = enabled;
    this.showBanner = false;
    this.save();
  };

  acceptAllCookies = () => {
    Object.keys(this.cookieTypes).forEach(key => {
      this.cookieTypes[key] = true;
    });
    this.showBanner = false;
    this.save();
  };

  acceptOnlyNecessaryCookies = () => {
    // only necessary ones here
    Object.keys(this.cookieTypes).forEach(key => {
      this.cookieTypes[key] = false;
    });
    this.showBanner = false;
    this.save();
  };

  checkGaTracking = () => {
    // fixme What should be done here?
    /* eslint-disable */
    window: {
      this.key = this.cookieTypes.performance;
    }
    /* eslint-enable */
  };

  save = () => {
    setCookie(COOKIE_NAME, JSON.stringify(this.toJSON()), '/', 180);
    this.checkGaTracking();
  };

  toJSON = () => {
    return {
      cookieTypes: this.cookieTypes,
      showBanner: this.showBanner,
    };
  };

  trackingEnabled() {
    return this.cookieTypes.performance;
  }
}

// export (singleton) instance
const privacyModel = new PrivacyModel();
export default privacyModel;
