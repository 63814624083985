// @flow
import React from 'react';
import LazyLoad from 'react-lazyload';
import DealerModel from 'Stores/DealerModel';
import Icon from 'Components/Icon';
import Button from 'Components/Button';
import styles from './styles.scss';
import {
  addressTracking,
  emailTracking,
  telephoneTracking,
  whatsappTracking,
} from '../../../utils/trackingAttributes';
import { hasCookie, getCookie } from './../../../../src/utils/index';
import privacyModel from 'Stores/PrivacyModel';
import { COOKIE_KOMFORT } from 'Plugins/CookieSettings';

type Props = {
  dealer: DealerModel,
  onOpenContact: () => void,
  showMapClicked: boolean,
  setShowMapClicked: () => void,
};

declare var PUBLIC_BASE_URL: string;

export default class Contact extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.mapWrapper = React.createRef();
    this.state = { width: 0, height: 0, blockUpdateOnResize: false };

    this.getStaticMapOrPlaceholder = this.getStaticMapOrPlaceholder.bind(this);
    this.setKomfortChange = this.setKomfortChange.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
    this.updateDimensions();
  }

  resetBlockUpdateOnResize = () => {
    this.setState({
      blockUpdateOnResize: false,
    });
  };

  updateDimensions = () => {
    if (this.mapWrapper && !this.state.blockUpdateOnResize) {
      setTimeout(() => {
        this.setState({
          width: Math.round(this.mapWrapper.current.clientWidth),
          height: Math.round(this.mapWrapper.current.clientHeight),
          blockUpdateOnResize: true,
        });
      }, 300);

      setTimeout(this.resetBlockUpdateOnResize, 400);
    }
    // note: dimension limit of the google map api is 640x640
  };

  setKomfortChange() {
    privacyModel.updateCookie(COOKIE_KOMFORT, true);
    this.props.setShowMapClicked();
  }

  showMap() {
    const hascookie = hasCookie('privacySettings')
      ? JSON.parse(getCookie('privacySettings'))
      : false;

    return (
      (hascookie && hascookie.cookieTypes && hascookie.cookieTypes.komfort) ||
      this.props.showMapClicked
    );
  }

  getStaticMapOrPlaceholder(contact) {
    if (this.showMap()) {
      return (
        <div className={styles.mapPlaceholder}>
          <LazyLoad height={240}>
            <img
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${contact.lat},${contact.lng}&zoom=14&size=${this.state.width}x${this.state.height}&markers=${contact.lat},${contact.lng}&key=AIzaSyD_BjpunA5w2PPqx9psElY106LvrnWvtQI`}
              alt="Logo"
            />
          </LazyLoad>
        </div>
      );
    }

    return (
      <div className={styles.mapPlaceholder}>
        <div className={styles.placeholderImageWrapper}>
          <img src={`${PUBLIC_BASE_URL}/img/map-placeholder.png`} alt="Map Placeholder" />
          <div className={styles.placeholderImageShadow} />
        </div>
        <div className={styles.placeholderInfo}>
          <div>
            <span className={styles.plaseHolderIcon}>
              <Icon name="address" />
            </span>
            <span className={styles.plaseHolderHeader}>Autohaus-Standort</span>
          </div>
          <div className={styles.plaseHolderText}>
            Gerne möchten wir Ihnen hier den Google Maps Standort unseres Autohauses anzeigen –
            akzeptieren Sie dazu den Button "Karte anzeigen". Sie können diese Einstellungen
            jederzeit <a href="/cookies/">hier</a> anpassen und ihre Einwilligung widerrufen.
            Weiteres finden Sie in unserer <a href="/datenschutz/">Datenschutzerklärung.</a>
          </div>
          <div className={styles.plaseHolderButton}>
            {' '}
            <Button
              label="Karte Anzeigen"
              type="secondary"
              onClick={this.setKomfortChange}
              light={true}
            />
          </div>
        </div>
      </div>
    );
  }

  onContactClick = () => {
    this.props.onOpenContact();
  };

  render() {
    const { dealer } = this.props;
    const contact = dealer.mainContact;
    const destination = `${dealer.name},${contact.street},${contact.postalCode}`
      .replace(/&/g, '%26')
      .replace(/\s+/g, '%20');

    const whatsappUrl =
      dealer.settings && dealer.settings.whatsappWidget && dealer.settings.whatsappWidget.isActive
        ? dealer.settings.whatsappWidget.whatsappUrl
        : undefined;

    return (
      <div className={styles.root}>
        <div className={styles.contactbox}>
          <div className={styles.row}>
            <div className={styles.map} ref={this.mapWrapper} data-with-whatsapp={!!whatsappUrl}>
              {this.getStaticMapOrPlaceholder(contact)}
            </div>
            <div className={styles.addressGutter}>
              <ul className={styles.address}>
                <li className={styles.addressrow}>
                  <h2 className={styles.headline}>{dealer.name}</h2>
                </li>

                <li className={styles.addressrow}>
                  <span className={styles.icon}>
                    <Icon name="phone" />
                  </span>

                  <div>
                    <a
                      href={`tel:${contact.phone}`}
                      {...telephoneTracking('Dealer Contact Footer')}
                    >
                      {contact.phone}
                    </a>
                  </div>
                </li>

                <li className={styles.addressrow}>
                  <span className={styles.icon}>
                    <Icon name="address" />
                  </span>

                  <div>
                    <div>
                      {contact.street} {contact.houseNumber}
                    </div>

                    <div>
                      D – {contact.postalCode} {contact.city}
                    </div>
                  </div>
                </li>

                <li className={styles.addressrow}>
                  <span className={styles.icon}>
                    <Icon name="mail" />
                  </span>

                  <div>
                    <a href={`mailto:${contact.email}`} {...emailTracking('Dealer Contact Footer')}>
                      {contact.email}
                    </a>
                  </div>
                </li>

                {whatsappUrl && (
                  <li className={styles.addressrow}>
                    <span className={styles.icon}>
                      <img
                        alt=""
                        className={styles.whatsappIcon}
                        src={`${process.env.ASSET_ENV_HOST}/assets/img/icon_whatsapp_green.svg`}
                      />
                    </span>

                    <div>
                      <a
                        href={whatsappUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        {...whatsappTracking('Footer')}
                      >
                        WhatsApp Chat starten
                      </a>
                    </div>
                  </li>
                )}

                <li className={`${styles.addressrow} ${styles.routeButton}`}>
                  <Button
                    label="Anfahrt"
                    type="primary"
                    href={`https://www.google.com/maps/dir/?api=1&destination=${destination}`}
                    target="_blank"
                    additionalProps={addressTracking('Dealer Contact Footer')}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
