// @flow
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import DealerModel from 'Stores/DealerModel';
import Icon from 'Components/Icon';
import Button from 'Components/Button';
import LocationSwitch from './LocationSwitch';
import Flyout from './Flyout';
import styles from './styles.scss';
import lsStyles from './LocationSwitch/styles.scss';
import classNames from 'classnames/bind';
import MainEntry from './mainentry.js';
import LeadNav from './LeadNav';
import { emailTracking, telephoneTracking, whatsappTracking } from 'Utils/trackingAttributes';
import { flyoutTeaserTracking } from 'Utils/trackingAttributes';
import { withUrlParams } from '../../utils/withUrlParams';

declare var PUBLIC_BASE_URL: string;

const cx = classNames.bind({ ...styles, ...lsStyles });

export const TOGGLE_LOCATION_SWITCH = 'toggleLocationSwitch';

type Props = {
  dealer: DealerModel,
  menu: Array<Object>,
  teaser: Object,
  history: Object,
  type: string,
  location: Object,
  onOpenContact: () => void,
  onOpenConfigurator: () => void,
  showMapClicked: boolean,
  setShowMapClicked: () => void,
  error?: ?boolean,
};

type State = {
  locationSwitchOpen: boolean,
  mapInit: boolean,
  flyoutOpen: boolean,
  mobileFlyoutOpen: boolean,
  flyoutSubOpen: boolean,
  flyouts: Array<Object>,
  resetActiveState: boolean,
};

class Header extends React.Component<Props, State> {
  static defaultProps = {
    dealer: new DealerModel(),
    teaser: {},
    menu: [],
  };

  constructor(props) {
    super(props);

    const flyouts = this.props.menu.map((entry) => {
      return {
        id: entry.id,
        label: entry.label,
        hideInMenu: entry.hideInMenu,
        subentries: entry.subentries,
        // using href here is workaround to handle 1lvl entries in HI without subentries
        href: entry.href,
        open: false,
        active: this.props.location.pathname.indexOf(entry.path) !== -1,
        transitionDelay: false,
      };
    });

    this.state = {
      locationSwitchOpen: false,
      mapInit: false,
      flyoutOpen: false,
      mobileFlyoutOpen: false,
      flyoutSubOpen: false,
      flyouts,
      resetActiveState: false,
    };
  }

  shouldComponentUpdate = (nextProps: Props, nextState: State) => {
    const { flyoutOpen, locationSwitchOpen, mobileFlyoutOpen, resetActiveState, showMapClicked } =
      this.state;
    // Re-render menu only when a flyout toggles
    return (
      flyoutOpen !== nextState.flyoutOpen ||
      locationSwitchOpen !== nextState.locationSwitchOpen ||
      mobileFlyoutOpen !== nextState.mobileFlyoutOpen ||
      resetActiveState !== nextState.resetActiveState ||
      showMapClicked !== nextProps.showMapClicked
    );
  };

  toggleLocationSwitch = () => {
    this.setState({
      locationSwitchOpen: !this.state.locationSwitchOpen,
      mapInit: true,
    });

    this.updateLocationSwitchScrollPosition();
  };

  updateLocationSwitchScrollPosition = () => {
    window.scrollTo(0, 0);

    window.setTimeout(() => {
      document.querySelector('.' + cx('header__wrapper')).scrollTop = document.querySelector(
        '.' + cx('header__location-switch')
      ).offsetHeight;
    }, 250);
  };

  openLocationSwitch = () => {
    this.setState({
      locationSwitchOpen: true,
      mapInit: true,
    });

    this.updateLocationSwitchScrollPosition();
  };

  handleFlyout = (entry: Object) => {
    const flyouts = this.state.flyouts;

    if (entry.id) {
      // id = -666 => deny
      if (entry.id !== -666) {
        const indexToOpen = this.state.flyouts.findIndex((flyout) => flyout.id === entry.id);
        const indexOpen = this.state.flyouts.findIndex((flyout) => flyout.open === true);
        const prevstate = flyouts[indexToOpen].open;

        this.state.flyouts.forEach((flyout, index) => {
          flyout.open = false;
          flyout.transitionDelay = true;
        });

        if (this.state.flyoutSubOpen) {
          flyouts[indexOpen].transitionDelay = false;
        } else {
          flyouts[indexToOpen].transitionDelay = false;
        }

        if (!entry.path) {
          flyouts[indexToOpen].open = !prevstate;
        }

        this.setState({
          flyoutOpen: !this.state.flyoutOpen,
          flyouts,
        });
      } else {
        document.body.classList.remove(cx('mobile-burger--open'));

        this.setState({
          mobileFlyoutOpen: false,
        });
      }
    } else {
      this.setState({
        mobileFlyoutOpen: !this.state.mobileFlyoutOpen,
      });

      if (!this.state.mobileFlyoutOpen) {
        document.body.classList.add(cx('mobile-burger--open'));
      } else {
        document.body.classList.remove(cx('mobile-burger--open'));
      }
    }

    document.querySelector('.' + cx('header__wrapper')).scrollTop = 0;
  };

  setEntryActiveState = (entry) => {
    const flyouts = this.state.flyouts;

    const indexToActivate = this.state.flyouts.findIndex((flyout) => flyout.id === entry.id);

    this.state.flyouts.map((flyout, index) => (flyouts[index].active = false));

    flyouts[indexToActivate].active = true;

    this.setState({
      flyouts,
    });
  };

  onMainEntryClick = (entry) => {
    if (!entry.subentries || entry.subentries.length === 0) {
      // using href here is workaround to handle 1lvl in HI entries without subentries
      this.props.history.push(entry.href);
      this.setEntryActiveState(entry);
      return;
    }

    if ((this.state.flyoutSubOpen && entry.open) || entry.path) {
      this.setState({
        flyoutSubOpen: false,
      });
    } else {
      this.setState({
        flyoutSubOpen: true,
      });
    }
    this.handleFlyout(entry);
  };

  onLinkClick = (mainentry) => {
    if (!this.state.mobileFlyoutOpen) {
      this.setEntryActiveState(mainentry);
      this.onMainEntryClick(mainentry);
    } else {
      this.handleFlyout({});
    }
  };

  onTeaserButtonClick = (entry) => {
    this.onMainEntryClick(entry);

    if (this.state.locationSwitchOpen) {
      this.updateLocationSwitchScrollPosition();
      return;
    }

    this.openLocationSwitch();
  };

  renderTeaser = (entry) => {
    const teaser = this.props.teaser[entry.id];
    const dfmm = { 'data-force-mobile-menu': this.props.forceMobileMenu };

    if (teaser) {
      const onClick =
        teaser.onClick === TOGGLE_LOCATION_SWITCH
          ? () => this.onTeaserButtonClick(entry)
          : teaser.onClick;

      return (
        <div className={styles.teaser} {...dfmm}>
          <h4 className={styles.headline} {...dfmm}>
            {teaser.text}
          </h4>

          {teaser.link ? (
            <div className={styles.button} {...dfmm}>
              <a href={teaser.link.href} target={teaser.link.target}>
                <Button
                  label={teaser.label}
                  onClick={onClick}
                  additionalProps={flyoutTeaserTracking(teaser.label.toLowerCase())}
                />
              </a>
            </div>
          ) : (
            <div className={styles.button}>
              <Button
                label={teaser.label}
                onClick={onClick}
                additionalProps={flyoutTeaserTracking(
                  teaser.customTrackingLabel || teaser.label.toLowerCase()
                )}
              />
            </div>
          )}
        </div>
      );
    }
    return <div />;
  };

  componentDidMount() {
    this.openLocationOnDeepLink();
    this.props.history.listen((location, action) => {
      const flyouts = this.state.flyouts;
      this.props.history.listen((location, action) => {
        this.state.flyouts.map((flyout, index) => (flyouts[index].active = false));

        this.setState({
          flyouts,
          resetActiveState: !this.state.resetActiveState,
        });
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.urlParams.showlocations !== this.props.urlParams.showlocations) {
      this.openLocationOnDeepLink();
    }

    if (prevProps.forceMobileMenu !== this.props.forceMobileMenu) {
      const flyouts = this.state.flyouts.map((item) => ({ ...item, open: false }));
      this.setState({
        flyoutSubOpen: false,
        flyoutOpen: false,
        flyouts,
      });
    }

    if (this.props.forceMobileMenu) {
      document.body.classList.add(cx('hide-scrollbar'));
    } else {
      document.body.classList.remove(cx('hide-scrollbar'));
    }
  }

  openLocationOnDeepLink = () => {
    if (this.props.urlParams.showlocations) {
      let isMobile = window.matchMedia('(max-width: 768px)');

      if (isMobile.matches || this.props.forceMobileMenu) {
        this.setState({
          locationSwitchOpen: true,
          mobileFlyoutOpen: true,
          flyoutSubOpen: false,
          flyoutOpen: false,
        });

        if (!this.state.mobileFlyoutOpen) {
          document.body.classList.add(cx('mobile-burger--open'));
        } else {
          document.body.classList.remove(cx('mobile-burger--open'));
        }

        setTimeout(() => {
          const card = document.querySelector('#locationcard');
          if (card) card.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'start' });
        }, 1000);
      } else {
        this.setState({
          locationSwitchOpen: true,
          flyoutSubOpen: false,
          flyoutOpen: false,
        });
      }
    }
  };

  render() {
    const {
      dealer,
      type,
      teaser,
      onOpenContact,
      onOpenConfigurator,
      updateMultipleUrlQuery,
      error,
      forceMobileMenu,
      setSharedState,
    } = this.props;

    const dfmm = { 'data-force-mobile-menu': forceMobileMenu };

    const wrapperClass = cx('header__wrapper', {
      'header__wrapper--open': this.state.mobileFlyoutOpen,
      'header__wrapper--sub-opened': this.state.flyoutSubOpen,
    });

    const locationSwitchClass = cx('meta-nav__item', 'meta-nav__item--ls', {
      'meta-nav__item--active': this.state.locationSwitchOpen,
    });

    const showLocationSwitch = dealer.hasFMMData && type === 'eki';

    const headerStyles = cx([styles.header], {
      'header--has-lead': true,
    });

    const whatsappUrl =
      dealer.settings && dealer.settings.whatsappWidget && dealer.settings.whatsappWidget.isActive
        ? dealer.settings.whatsappWidget.whatsappUrl
        : undefined;

    return (
      <header className={headerStyles} {...dfmm}>
        <div className={wrapperClass} {...dfmm}>
          <div className={cx('header__bar')} {...dfmm}>
            {type === 'eki' ? (
              <div className={cx('header__lead')} {...dfmm}>
                <div className={cx('gr-container')} {...dfmm}>
                  {!error && (
                    <LeadNav
                      dealer={dealer}
                      handleFlyout={this.handleFlyout}
                      onOpenContact={onOpenContact}
                      onOpenConfigurator={onOpenConfigurator}
                      updateMultipleUrlQuery={updateMultipleUrlQuery}
                      forceMobileMenu={forceMobileMenu}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className={cx('header__lead')} {...dfmm}>
                <div className={cx('gr-container')} {...dfmm}>
                  <LeadNav.Admin />
                </div>
              </div>
            )}
            <div className={cx('header__meta')}>
              <div className={cx('gr-container')} {...dfmm}>
                <div className={cx('meta-logo')} {...dfmm}>
                  <Link to={type === 'hi' ? '/admin' : '/'}>
                    <img
                      src={`${PUBLIC_BASE_URL}/img/logo-mazda.png`}
                      alt="Logo Mazda"
                      className={cx('meta-logo__image')}
                      {...dfmm}
                    />
                    {type === 'hi' && 'Händlerinterface'}
                  </Link>
                  {dealer.settings.servicePartnerOnly && (
                    <Fragment>
                      <span className={cx('service')}>Servicepartner</span> <br />
                    </Fragment>
                  )}
                </div>
                {type === 'eki' && dealer.hasFMMData && (
                  <ul className={cx('meta-nav')} {...dfmm}>
                    <Fragment>
                      {dealer.settings.socialMedia &&
                        dealer.settings.socialMedia.dealerlink &&
                        dealer.settings.socialMedia.dealerlink !== '' && (
                          <li
                            className={cx('meta-nav__item', 'meta-nav__item--dealer-website')}
                            {...dfmm}
                          >
                            <a
                              href={`${dealer.settings.socialMedia.dealerlink}`}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {dealer.settings.socialMedia.dealerlinkTitle !== ''
                                ? dealer.settings.socialMedia.dealerlinkTitle
                                : 'zur Händlerwebseite'}
                            </a>
                          </li>
                        )}
                      <li
                        className={locationSwitchClass}
                        onClick={this.toggleLocationSwitch}
                        data-e2e="ourlocations"
                        {...dfmm}
                      >
                        {dealer.branches && dealer.branches.length > 1 ? (
                          <span>Unsere Standorte</span>
                        ) : (
                          <span>Standortdetails</span>
                        )}
                        <Icon name="caret-down" className={styles.icon} inline={false} />
                      </li>
                      <li className={cx('meta-nav__item', 'meta-nav__item--phone')} {...dfmm}>
                        <a
                          href={`tel:${dealer.mainContact.phone}`}
                          {...telephoneTracking('Navigation Bar')}
                        >
                          {dealer.mainContact.phone}
                        </a>
                      </li>
                      <li className={cx('meta-nav__item', 'meta-nav__item--email')} {...dfmm}>
                        <a
                          href={`mailto:${dealer.mainContact.email}`}
                          {...emailTracking('Navigation Bar')}
                        >
                          E-Mail
                        </a>
                      </li>
                      {whatsappUrl && (
                        <li className={cx('meta-nav__item', 'meta-nav__item--email')} {...dfmm}>
                          <a
                            href={whatsappUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            {...whatsappTracking('Navigation Bar')}
                          >
                            WHATSAPP
                          </a>
                        </li>
                      )}
                    </Fragment>
                  </ul>
                )}
                {type === 'hi' && (
                  <ul className={cx('meta-nav')} {...dfmm}>
                    <li className={cx('meta-nav__item')} {...dfmm}>
                      <a
                        href="https://portal.mazdaeur.com/mazdapedia/mazda-haendlerwebseite.html"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        FAQs
                      </a>
                    </li>
                    <li className={cx('meta-nav__item', 'meta-nav__item--border')} {...dfmm}>
                      <Link to="/">Zur Webseite</Link>
                    </li>
                    <li className={cx('meta-nav__item', 'meta-nav__item--border')} {...dfmm}>
                      <a href="/admin/?logintype=logout">Logout</a>
                    </li>
                  </ul>
                )}
              </div>
            </div>
            {showLocationSwitch && (
              <LocationSwitch
                dealer={dealer}
                open={this.state.locationSwitchOpen}
                init={this.state.mapInit}
                toggle={this.toggleLocationSwitch}
                showMapClicked={this.props.showMapClicked}
                setShowMapClicked={this.props.setShowMapClicked}
                forceOneCard={forceMobileMenu}
                setSharedState={setSharedState}
              />
            )}
            <div className={cx('header__main')}>
              <div className={cx('gr-container')} {...dfmm}>
                {type === 'eki' && (
                  <div className={cx('main-logo')} {...dfmm}>
                    <Link to="/">
                      {dealer.hasLogo && dealer.id && (
                        <div
                          style={{
                            backgroundImage: `url(${PUBLIC_BASE_URL}/img/logos/${dealer.id}.webp)`,
                          }}
                          className={cx('main-logo__image')}
                        />
                      )}
                      {!dealer.hasLogo && (
                        <div className={cx('main-logo__text')}>{dealer.name}</div>
                      )}
                    </Link>
                  </div>
                )}
                <ul className={cx('main-nav')} {...dfmm}>
                  <li className={cx('main-nav__activeLine')} {...dfmm}>
                    <hr />
                  </li>
                  {this.state.flyouts.map((entry, i) => {
                    return (
                      <MainEntry
                        key={`lvl-1-${entry.id}`}
                        entry={entry}
                        onClick={this.onMainEntryClick}
                        open={entry.open}
                        active={entry.active}
                        forceMobileMenu={forceMobileMenu}
                      />
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          {this.state.flyouts.map((entry) => {
            if (entry.subentries) {
              return (
                <Flyout
                  key={`lvl-2-${entry.id}`}
                  open={entry.open}
                  design={entry.design}
                  entry={entry}
                  onLinkClick={this.onLinkClick}
                  onMainEntryClick={this.onMainEntryClick}
                  transitionDelay={entry.transitionDelay}
                  onOpenContact={onOpenContact}
                  onOpenConfigurator={onOpenConfigurator}
                  isServicePartnerOnly={this.props.dealer.isServicePartnerOnly()}
                  isSalesPartnerOnly={this.props.dealer.isSalesPartnerOnly()}
                  isOsbUser={this.props.dealer.settings.isOsbUser}
                  updateMultipleUrlQuery={this.props.updateMultipleUrlQuery}
                  forceMobileMenu={forceMobileMenu}
                >
                  {teaser && this.renderTeaser(entry)}
                </Flyout>
              );
            }
            return '';
          })}
        </div>
        <div className={cx('header__mobile')}>
          <div className={cx('mobile-logo')}>
            <Link to="/">
              <img
                src={`${PUBLIC_BASE_URL}/img/logo-mazda.png`}
                alt="Logo Mazda"
                className={cx('meta-logo__image')}
                {...dfmm}
              />
            </Link>
          </div>
          <div className={cx('mobile-dealer')}>
            {dealer.settings.servicePartnerOnly && (
              <span className={cx('service')}>Servicepartner</span>
            )}
            <span className={cx('dealerName')}>
              {type === 'eki' ? dealer.name : 'Händlerinterface'}
            </span>
          </div>
          <div
            className={cx('mobile-burger', this.state.mobileFlyoutOpen && 'mobile-burger--open')}
            onClick={this.handleFlyout}
            {...dfmm}
          >
            <ul className={cx('mobile-burger__icon')}>
              <li />
              <li />
              <li />
            </ul>
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(withUrlParams(Header));
