// @flow
import React from 'react';
import PropTypes from 'prop-types';
import { Watch } from 'scrollmonitor-react';
import FocusedImage from 'Components/FocusedImage';
import Icon from 'Components/Icon';
import { Container, Row, Col } from 'Components/Grid';
import AssetModel from 'Stores/AssetModel';
import styles from './styles.scss';
import classNames from 'classnames/bind';
import CallToAction from 'Components/CallToAction';
import { withRouter } from 'react-router-dom';
import ConsumptionData from 'Components/ConsumptionData';
import RichText from 'Components/RichText';

const cx = classNames.bind(styles);

type Props = {
  id: Number,
  small?: boolean,
  full?: boolean,
  title?: string,
  subtitle?: string,
  introtext?: string,
  image?: AssetModel,
  backlink?: string,
  whiteBg?: boolean,
  isInViewport: boolean,
  stopWatcher: () => void,
  deeplinkId?: number,
};

class HeroHeader extends React.Component<Props> {
  static defaultProps = {
    image: new AssetModel(),
    backlink: '/',
  };

  static contextTypes = {
    router: PropTypes.object,
  };

  UNSAFE_componentWillReceiveProps = (props) => {
    if (props.isInViewport) {
      props.stopWatcher();
    }
  };

  onClick = () => {
    this.props.history.push(this.props.backlink);
  };

  renderCta = (cta, buttonType, isLight) => {
    const { onOpenContact } = this.props;

    return (
      <div className={styles.cta}>
        <CallToAction
          {...cta}
          onOpenContact={onOpenContact}
          buttonType={buttonType}
          light={isLight}
        />
      </div>
    );
  };

  renderPrimaryCta = (cta) => {
    return (
      <div className={styles.cta}>
        <CallToAction {...cta} />
      </div>
    );
  };

  render() {
    const {
      title,
      subtitle,
      small,
      // compact,
      backlink,
      introtext,
      whiteBg,
      full,
      cta,
      primaryCta,
      id, // eslint-disable-line
      deeplinkId,
      consumptionText,
      type,
      contentPosition,
    } = this.props;
    const image = new AssetModel(this.props.image);

    let compact = type === 'mask_hero_header_compact';
    const contentLeft = contentPosition === 'left';
    const contentRight = contentPosition === 'right';

    const className = cx('heroheader', {
      small,
      full,
      compact,
      whiteBg: [whiteBg],
      inView: window.isPreview || this.props.isInViewport,
    });

    return (
      <div className={className} id={deeplinkId}>
        <div className={styles.image}>
          <FocusedImage
            image={Object.assign(image, { dim: 0.3 })}
            useThumb={image && image.shouldUseThumb()}
          />

          {compact && (
            <div className={styles.containerCompact}>
              <Container style={{ width: '100%' }}>
                <div
                  className={cx('compactContent', {
                    contentLeft,
                    contentRight,
                  })}
                >
                  {subtitle && (
                    <div
                      className={styles.subtitle}
                      dangerouslySetInnerHTML={{ __html: subtitle }}
                    />
                  )}
                  <h1 dangerouslySetInnerHTML={{ __html: title }} />
                  {cta && cta.label && this.renderCta(cta, 'primary', cta.theme === 'light')}
                </div>
              </Container>
            </div>
          )}

          {title && !small && !compact && (
            <div className={styles.container}>
              <div className={styles.content}>
                {!full && <h1 dangerouslySetInnerHTML={{ __html: title }} />}
                {full && (
                  <React.Fragment>
                    {subtitle && (
                      <div
                        className={styles.subtitle}
                        dangerouslySetInnerHTML={{ __html: subtitle }}
                      />
                    )}
                    <h1 dangerouslySetInnerHTML={{ __html: title }} />
                    {cta && cta.label && this.renderCta(cta, 'secondary', true)}
                  </React.Fragment>
                )}
              </div>
            </div>
          )}
        </div>
        {backlink && small && (
          <div className={styles.backlink}>
            <button onClick={this.onClick}>
              <Icon name="backward" className={styles.icon} inline={false} />
              zurück
            </button>
          </div>
        )}

        {(small || compact) && (
          <div
            className={cx('content', {
              compactContentMobile: !!compact,
            })}
          >
            <Row align="center">
              <Col cols={12} m={10} l={8}>
                {subtitle && (
                  <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />
                )}
                {title && (
                  <h1 className={styles.likeh2} dangerouslySetInnerHTML={{ __html: title }} />
                )}
                {introtext && <RichText content={introtext} />}
                {primaryCta && primaryCta.label && this.renderPrimaryCta(primaryCta)}
                {compact && cta && cta.label && this.renderCta(cta, 'primary', false)}
              </Col>
            </Row>
          </div>
        )}
        {consumptionText && <ConsumptionData consumptionText={[consumptionText]} />}
      </div>
    );
  }
}

export default withRouter(Watch(HeroHeader));
