// @flow
import { isAfter, differenceInDays } from 'date-fns';
import AssetModel from 'Stores/AssetModel';
import { findFootnotes, getRTECharCount } from 'Utils';

export function isValidMail(email: string): boolean {
  return /^([^.@\s][^@\s]*[^.@\s]|[^.@\s])@[^.@\s]+\.[^@\s]+[^.@\s]$/.test(email);
}

export function isValidUrl(url: string): boolean {
  return /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/.test(
    url
  );
}

export function validateEmail(errors: {}, prop: string, value: string) {
  if (!isValidMail(value)) {
    errors[prop] = 'Bitte prüfen Sie die eingegebene E-Mailadresse';
  } else {
    delete errors[prop];
  }
}

export function isValidTime(time: string): boolean {
  return /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time);
}

export function validateTime(errors: {}, prop: string, value: string) {
  if (!isValidTime(value)) {
    errors[prop] = 'Bitte prüfen Sie die eingegebene Uhrzeit (HH:mm)';
  } else {
    delete errors[prop];
  }
}

export function validateUrl(errors: {}, prop: string, value: string) {
  value = value ? value.toLowerCase() : '';
  if (value.length === 0) {
    delete errors[prop];
  } else if (value.indexOf('http://') !== 0 && value.indexOf('https://') !== 0) {
    errors[prop] = 'Bitte beginnen Sie die URL mit http:// oder https://';
  } else if (!isValidUrl(value)) {
    errors[prop] = 'Bitte prüfen Sie die eingegebene URL';
  } else {
    delete errors[prop];
  }
}

export function validateLength(
  errors: {},
  prop: string,
  value: string = '',
  min: number,
  max?: number
) {
  if (min === 1 && value.length === 0) {
    errors[prop] = 'Bitte füllen Sie dieses Feld aus';
  } else if (value.length < min) {
    errors[prop] = `Die Eingabe muss mindestens ${min} Zeichen betragen`;
  } else if (max && value.length > max) {
    errors[prop] = `Die Eingabe darf maximal ${max} Zeichen betragen`;
  } else {
    delete errors[prop];
  }
}

export function validateRTELength(
  errors: {},
  prop: string,
  value: string,
  min: number,
  max: number
) {
  const count = getRTECharCount(value, true);
  if (min === 1 && count === 0) {
    errors[prop] = 'Bitte füllen Sie dieses Feld aus';
  } else if (count < min) {
    errors[prop] = `Die Eingabe muss mindestens ${min} Zeichen betragen`;
  } else if (count > max) {
    errors[prop] = `Die Eingabe darf maximal ${max} Zeichen betragen`;
  } else {
    delete errors[prop];
  }
}

export function validateNumber(errors: {}, prop: string, value: number, min: number, max?: number) {
  if (!value && value !== 0) {
    errors[prop] = 'Bitte geben Sie einen gültigen Wert an';
  } else if (min >= 0 && value < min) {
    errors[prop] = `Bitte geben Sie einen Wert an, der größer ${min} ist`;
  } else if (max && value > max) {
    errors[prop] = `Bitte geben Sie einen Wert an, der kleiner ${max} ist`;
  } else {
    delete errors[prop];
  }
}

export function validateNotNull(errors: {}, prop: string, value: any) {
  if (!value) {
    errors[prop] = 'Bitte prüfen Sie dieses Feld';
  } else {
    delete errors[prop];
  }
}

export function validateEmpty(errors: {}, prop: string, value: any) {
  if (value || value !== '') {
    errors[prop] = 'Bitte prüfen Sie dieses Feld';
  } else {
    delete errors[prop];
  }
}

export function validateDateAfter(errors: {}, prop: string, date: ?Date, dateToCompare: ?Date) {
  // Compare the two dates and return 1 if the first date is after the second, -1 if the first
  // date is before the second or 0 if dates are equal.
  if (!isAfter(date, dateToCompare)) {
    errors[prop] = 'Das End- muss hinter dem Startdatum liegen';
  } else {
    delete errors[prop];
  }
}

export function validateImageSelect(errors: {}, prop: string, image: ?AssetModel) {
  if (!image) {
    errors[prop] = 'Bitte wählen Sie ein Bild aus';
  } else {
    delete errors[prop];
  }
}

export function isInvalid(errors: {}): boolean {
  return Object.keys(errors).length > 0;
}

/**
 Replaces placeholders with data found in global window scope
 E.g. "...{dealer.dealerId}..." will be replaced by window.dealer.dealerId
 **/

export function injectUrlVars(url: string): string {
  const placeholders = url.match(/\{[^}]+?\}/g);
  const resolveVars = (object: any, vars: Array<string>) => {
    if (vars.length > 0) {
      // use copy of the actual array to remove sideeffects on consecutive calls (e.g. re-rendering)
      const remainingVars = [...vars];
      // resolves the first variable and passes the remaining variables
      return resolveVars(object[remainingVars.shift()], remainingVars);
    }
    return object;
  };

  if (placeholders) {
    Array.from(placeholders).forEach(placeholder => {
      // strip curly braces and retrieve variables
      const vars = placeholder.substr(1, placeholder.length - 2).split('.');
      url = url.replace(placeholder, resolveVars(window, vars));
    });
  }

  return url;
}

export const isValidCTAUrl = url =>
  /^((((http(s)?:)?\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6})|\/)\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/.test(
    url
  );

export const validateCTAUrl = (errors: {}, prop: string, value: string, emptyAllowed = true) => {
  value = value.toLowerCase();

  if (value.length === 0) {
    if (emptyAllowed) {
      delete errors[prop];
    } else {
      errors[prop] = 'Bitte geben Sie eine URL an';
    }
  } else if (!isValidCTAUrl(value)) {
    if (value.indexOf('http://') > 0 && value.indexOf('https://') > 0 && value.indexOf('/') > 0) {
      errors[prop] = 'Bitte beginnen Sie die URL mit http://, https:// oder /';
    } else {
      errors[prop] = 'Bitte prüfen Sie die eingegebene URL';
    }
  } else {
    delete errors[prop];
  }
};

export const createLocalFootnotesMessage = (value: string) => {
  const footnotes = findFootnotes(value).filter(fn => fn.relation === 'single');

  if (footnotes.length < 1) {
    return null;
  }

  const last = footnotes.pop().id;
  const list = footnotes.map(fn => fn.id).join(', ');

  // single: Bitte ersetzen Sie die Fußnote FN1 durch eine globale Fußnote.
  // multiple: Bitte ersetzen Sie die Fußnoten FN1, FN2 und FN3 durch globale Fußnoten.
  return 'Bitte ersetzen Sie die '.concat(
    footnotes.length > 0 ? 'Fußnoten ' : 'Fußnote ',
    footnotes.length > 0 ? [list, last].join(' und ') : last,
    ` durch ${footnotes.length > 0 ? 'globale Fußnoten' : 'eine globale Fußnote'}.`
  );
};

export const validateNoLocalFootnotes = (errors: {}, prop: string, value: string) => {
  const message = createLocalFootnotesMessage(value);

  if (message === null) {
    delete errors[prop];
    return;
  }

  errors[prop] = message;
};

export const validateDaysBetween = (
  errors: {},
  prop: string,
  start: Date,
  end?: Date,
  config: {} = { noEndAllowed: true, days: 365 }
) => {
  if (!end) {
    if (config.noEndAllowed) {
      delete errors[prop];
    } else {
      errors[prop] = 'Bitte geben Sie ein Enddatum an';
    }

    return;
  }

  const diff = differenceInDays(end, start);

  if (diff < 0) {
    errors[prop] = 'Das End- muss hinter dem Startdatum liegen';
    return;
  }

  if (diff > config.days) {
    let text = `${config.days} Tage`;

    if (config.days === 365) {
      text = '1 Jahr';
    }

    errors[prop] = `Das Enddatum darf maximal ${text} vom Startdatum entfernt liegen`;
  } else {
    delete errors[prop];
  }
};
