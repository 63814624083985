import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'Components/Grid';
import Contact from './Contact';
import OpeningHours from './OpeningHours';
import classNames from 'classnames';
import SocialMedia from './SocialMedia';
import DealerModel from 'Stores/DealerModel';
import styles from './styles.scss';
import { footerTracking } from 'Utils/trackingAttributes';

export default class Footer extends React.Component {
  static propTypes = {
    dealer: PropTypes.instanceOf(DealerModel),
    visible: PropTypes.bool,
    onOpenContact: PropTypes.func,
    showMapClicked: PropTypes.bool,
    setShowMapClicked: PropTypes.func,
  };

  componentDidMount() {
    window.app_version = APP_VERSION;
  }

  render() {
    const { dealer, visible } = this.props;
    const style = { opacity: visible ? 1 : 0 };

    const className = classNames(styles.footer, this.props.className);

    return (
      <Fragment>
        <div id="fmm-footnotes" />

        <footer className={className} style={style}>
          {dealer.hasFMMData && <Contact {...this.props} />}
          <Container>
            {dealer.openingHours.length > 0 && <OpeningHours openingHours={dealer.openingHours} />}
            <SocialMedia dealer={dealer} />
            <Row>
              <Col>
                <div className={styles.legal}>
                  <Link
                    className={styles.legalLink}
                    to="/impressum"
                    {...footerTracking('Impressum')}
                  >
                    Impressum
                  </Link>
                  <Link
                    className={styles.legalLink}
                    to="/datenschutz"
                    {...footerTracking('Datenschutz')}
                  >
                    Datenschutz
                  </Link>
                  <Link className={styles.legalLink} to="/cookies" {...footerTracking('Cookies')}>
                    Cookies
                  </Link>
                  <Link
                    className={styles.legalLink}
                    to="/rechtliche-hinweise"
                    {...footerTracking('Rechtliche Hinweise')}
                  >
                    Rechtliche Hinweise
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
      </Fragment>
    );
  }
}
